@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');

:root {
  --background-color: #fff;
  --text-color: rgba(0, 0, 0, 0.87);
  --headline-color: #2b5088;
}

html,
body {
  font-size: 18px;
  color: var(--text-color);
  font-family: 'Roboto', sans-serif;
  line-height: 1.65;
  background-color: var(--background-color);
  margin: 0;
  padding: 0;
}

h1 {
  color: var(--headline-color);
  font-weight: 300;
  margin-bottom: 0;
  font-size: 3.052rem;
  line-height: 1.15;
  border-style: solid;
  border-width: 0 0 2px 0;
  margin-bottom: 0.33rem;
  width: fit-content;
  border-image: linear-gradient(
      90deg,
      rgba(112, 154, 217, 1) 0%,
      rgba(178, 107, 103, 1) 68%,
      rgba(253, 156, 57, 1) 100%
    )
    1;
}

hgroup {
  margin-bottom: 3rem;
}

.subheadline {
  opacity: 0.8;
  color: var(--headline-color);
}

p {
  max-width: 30rem;
  text-align: justify;
}

a,
a:visited {
  color: var(--headline-color);
}

main {
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  width: 100vw;
}

.side {
  min-width: 50%;
  flex-grow: 1;
  box-sizing: border-box;
}

.picture {
  background-image: url('/side-image.jpg');
  background-size: cover;
  background-position: center;
}

.information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
}

@media (max-width: 50rem) {
  .picture {
    display: none;
  }

  h1 {
    font-size: 1.953rem;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #121212;
    --text-color: #fefefe;
    --headline-color: #709ad9;
  }

  .picture {
    opacity: 0.75;
  }

  h1 {
    border-image: linear-gradient(
        90deg,
        rgba(255, 126, 0, 0.66) 0%,
        rgba(255, 69, 0, 0.66) 100%
      )
      1;
  }
}
